.page-nav {
  @extend .list-unstyled;
  text-align: center;
  @extend .d-flex;
  @extend .align-items-center;
  flex-direction: column;
  ul {
    @extend .list-unstyled;
  }
  > li {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    background-color: $background-light;
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    margin-bottom: $grid-gutter-width;
    > a {
      @extend %nav-level2;
    }
  }
  > li ul li {
    display: none;
  }
  > li.active {
    background-color: $primary;
    height: 11rem;
    width: 11rem;
    a {
      color: #fff;
    }
    ul li {
      display: block;
    }
  }
}
@include media-breakpoint-down(lg) {
  .page-nav {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.page {
  padding-top: $grid-gutter-width*2;
  .main-content {
    background-color: $background-light;
    padding: $grid-gutter-width;
    .image-insert {
      float: right;
      margin-left: $grid-gutter-width;
    }
    img {
      max-width: 100%;
    }
  }
  .wall {
    // margin-left: -$grid-gutter-width/2;
    // margin-right: -$grid-gutter-width/2;
    .brick {
      margin: $grid-gutter-width / 2;
    }
    button {
      border: 0px solid #fff;
      padding: 0;
      margin: 0;
    }
  }
}

.logo {
  img {
    width: 10rem;
    margin: 1rem auto;
    display: block;
  }
}

button.hamburger {
  display: none;
  border: 0px solid #ccc;
  background-color: transparent;
}
@include media-breakpoint-down(lg) {
  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 4rem;
    }
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
  }
  button.hamburger {
    display: block;
  }
  .page-nav.hide {
    display:none !important;
  }
  .page {
    padding-top: 0;
  }
}


.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.modal-page-image {
  .modal-body {
    text-align: center;
  }
  img {
    max-width: 100%;
  }
}