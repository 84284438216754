.home {
  @extend .bg-cover;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    display: none;
  }
  .circle {
    text-align: center;
    border-radius: 50%;
    background-color: $background-light;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: $grid-gutter-width / 2;
  }
  .nav-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .level-1 {
    font-size: $h1-font-size;
    font-family: 'Oleo Script', cursive;
    img {
      max-width: 45%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    line-height: 1em;
  }
  .level-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @extend %nav-level2;
  }
  .nav-group:hover .level-2 {
    display: flex;
  }
  .level-1 .circle {
    width: 14rem;
    height: 14rem;
  }
  .level-2 .circle {
    width: 8rem;
    height: 8rem;
  }
  
  @include media-breakpoint-up(sm) {
    justify-content: space-around;
    padding: 0 3rem;
    // background-color: red;
    .level-2 {
      display: none;
    }
    .nav-group:nth-child(2) {
      justify-content: flex-start;
    }
    .nav-group:last-child .level-1 {
      order: 1;
    }
    .nav-group:last-child {
      justify-content: flex-end;
    }
  }
}
