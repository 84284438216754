@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400&family=Oswald&display=swap');

$headings-font-family: 'Oswald', sans-serif;
$link-decoration: none;
$link-hover-decoration: underline;
$primary: #8B6737;
@import "~bootstrap/scss/bootstrap";

html,
body,
.App,
#root {
  height: 100%;
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

%nav-level2 {
  text-transform: uppercase;
  font-size: $h5-font-size;
  font-family: $headings-font-family;
}
h1 {
  font-family: 'Oleo Script', cursive;
}
.as-button {
  @extend .btn;
  @extend .btn-primary;
}
$background-light: rgba(254, 244, 227, 0.75);
$background-white: rgba(255, 255, 255, 0.75);
@import './Home';
@import './Page';
@import './HomeStudio';
@import './Contact';
