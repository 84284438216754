.home-studio {
  @extend .d-flex;
  @extend .flex-column;
  @extend .justify-content-center;
  @extend .bg-cover;
  text-align: center;
  min-height: 100%;
  > .background-wrapper {
    background-color: $background-light;
    padding: 3em 0;
    > .container > .row {
      @extend .d-flex;
      @extend .align-items-center;
    }
  }
  .h1-circle {
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    margin: $grid-gutter-width auto;
    text-align: center;
    border-radius: 50%;
    height: 15rem;
    width: 15rem;
    padding: 1rem;
    background-color: $background-white;
    h1 {
      margin: 0;
      color: $primary;
    }
  }
  .studio-nav {
    @extend .d-flex;
    @extend .justify-content-around;
    @extend .flex-wrap;
    @extend %nav-level2;
    margin-top: $paragraph-margin-bottom * 2;
    margin-bottom: $paragraph-margin-bottom * 2;
    .circle {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-center;
      text-align: center;
      height: 5em;
      width: 5em;
      background-color: $background-light;
      border-radius: 50%;
      a {
        display: block;
      }
    }
  }
}