.contact {
  @extend .page;
  form {
    label,
    textarea,
    input {
      display: block;
      width: 100%;
    }
    textarea,
    input {
      margin-bottom: $paragraph-margin-bottom;
    }
  }
  > .container > .row {
    @extend .justify-content-center;
  }
}